import {
  useEffect,
  useState,
} from 'react';

import {
  Link,
  useLocation,
} from 'react-router-dom';

const Header = () => {
    const [isMenuToggle, setMenuToggle] = useState(false)
    let pageName;

    const menuToggle = () => {
      setMenuToggle(!isMenuToggle);
    };

    const location = useLocation();
    const activeStyle = "block py-2 pl-3 md:pl-0 bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded md:rounded-none border-l-4 md:bg-transparent dark:md:bg-transparent mb-1 mx-0 xl:mx-2 dark:text-white text-gray-900 md:border-b md:border-l-0 border-primary" 
    const inactiveStyle = "block py-2 pl-3 md:pl-0 bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded md:bg-transparent dark:md:bg-transparent mb-1 mx-0 xl:mx-2 text-gray-700 hover:bg-gray-900 hover:bg-opacity-10 md:border-b md:border-transparent dark:hover:bg-white dark:hover:bg-opacity-10 dark:text-gray-400 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-gray-900 md:dark:hover:bg-transparent md:dark:hover:text-white"

    useEffect(() => {
      if (location.pathname === "/" || location.pathname === "/landing") {
        pageName = "Velodrome Finance";
      } else {
        const name = location.pathname.replace('/', '')
        pageName = name.charAt(0).toUpperCase() + name.slice(1);
      }
      const canonicalUrl = window.location.href;
      const canonicalLink = document.querySelector("link[rel='canonical']")
  
      canonicalLink.href = canonicalUrl;
      document.title = pageName;
    }, [location.pathname]);
    

    return (
        <div className="py-4 border-b border-gray-900 border-opacity-5 dark:border-white dark:border-opacity-5">
        <nav className="py-2.5 rounded">
          <div className="mx-auto flex flex-wrap items-center justify-between">
            <Link className="flex items-center" to="/">
              <img
                src="velodrome.svg"
                className="ml-2 mr-3 h-9"
                alt="Velodrome Finance"
              />
              <span className="hidden lg:inline">
                <img
                  src="wordmark.svg"
                  className="h-5 dark:hidden"
                  alt="Velodrome Finance"
                />
                <img
                  src="wordmark_white.svg"
                  className="hidden h-5 dark:inline"
                  alt="Velodrome Finance"
                />
              </span>
            </Link>
            <div className="flex md:order-2">
            <Link to="/connect"
                className="text-white bg-primary border border-transparent hover:bg-red-700 dark:bg-primary dark:hover:bg-red-700 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg">
                <span className="flex items-center rounded-md text-sm px-4 py-2">
                Connect
                </span>
              </Link>

              <button
                data-testid="flowbite-navbar-toggle" onClick={menuToggle}
                className="inline-flex items-center rounded-lg p-2 text-sm text-gray-500 dark:text-gray-400 md:hidden bg-gray-900 bg-opacity-5 hover:bg-gray-900 hover:bg-opacity-10 dark:bg-white dark:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-10 ml-2"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 12 16"
                  aria-hidden="true"
                  className="h-4 w-6 shrink-0"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.41 9H.59C0 9 0 8.59 0 8c0-.59 0-1 .59-1H11.4c.59 0 .59.41.59 1 0 .59 0 1-.59 1h.01zm0-4H.59C0 5 0 4.59 0 4c0-.59 0-1 .59-1H11.4c.59 0 .59.41.59 1 0 .59 0 1-.59 1h.01zM.59 11H11.4c.59 0 .59.41.59 1 0 .59 0 1-.59 1H.59C0 13 0 12.59 0 12c0-.59 0-1 .59-1z"
                  />
                </svg>
              </button>
            </div>
            <div
              data-testid="flowbite-navbar-collapse"
              className={`w-full md:block md:w-auto ${ isMenuToggle ? '' : 'hidden' }`}
            >
              <ul className="mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium">
                <li>
                  <Link
                    className={`${location.pathname === '/swap' ? activeStyle : inactiveStyle}`}
                    to="/swap"
                  >
                    Swap
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${location.pathname === '/liquidity' ? activeStyle : inactiveStyle}`}
                    to="/liquidity"
                  >
                    Liquidity
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    )
}

export default Header;