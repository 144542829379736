import {
  useEffect,
  useState,
} from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import {
  useWeb3Modal,
  useWeb3ModalAccount,
} from '@web3modal/ethers5/react';
import {
  devLog,
  truncate,
} from '../utils/helpers';
import useWeb3Actions from "./hooks/useWeb3Actions"

import { useGatekeeper } from "../context/Gatekeeper";


const Connect = () => {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const { isLoading, setIsLoading } = useGatekeeper();
  const { action } = useWeb3Actions({ isLoading, setIsLoading })

  const location = useLocation();


  useEffect(() => {
    const canonicalUrl = window.location.href;
    const canonicalLink = document.querySelector("link[rel='canonical']")
    canonicalLink.href = canonicalUrl;
    const name = location.pathname.replace('/', '')
    const pageName = name.charAt(0).toUpperCase() + name.slice(1);
    document.title = pageName
  }, [location.pathname]);

    return(
        <div>
            <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
                <div className="flex flex-col" />
            </div>
            <div className="lg:flex bg-neutral-50 dark:bg-gray-900 bg-worm dark:bg-worm-light bg-no-repeat bg-[left_bottom_-300px]">
                <div className="lg:w-1/2 flex justify-center self-center">
                <div className="hidden lg:inline pt-16 lg:pt-0 lg:w-3/5">
                    <Link to="/">
                    <div className="flex items-end mb-16">
                        <div className="flex items-center">
                        <img
                            src="velodrome.svg"
                            className="ml-2 mr-3 h-9"
                            alt="Velodrome Finance"
                        />
                        <img
                            src="wordmark.svg"
                            className="h-5 dark:hidden"
                            alt="Velodrome Finance"
                        />
                        <img
                            src="wordmark_white.svg"
                            className="hidden h-5 dark:inline"
                            alt="Velodrome Finance"
                        />
                        </div>
                    </div>
                    </Link>
                    <div className="hidden lg:inline">
                    <div className="text-4xl font-bold font-serif tracking-wider leading-[3.2rem] dark:text-white">
                        The central trading and liquidity marketplace on{" "}
                        <span className="text-3xl font-sans uppercase italic tracking-tight font-extrabold text-primary">
                        Optimism
                        </span>
                    </div>
                    <ul className="mt-16 mb-24 space-y-3 text-sm dark:text-white">
                        <li className="flex gap-3 items-center">
                        <span className="text-primary">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-check-circle"
                            >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                        </span>
                        Low fee, low slippage swaps
                        </li>
                        <li className="flex gap-3 items-center">
                        <span className="text-primary">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-check-circle"
                            >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                        </span>
                        Support for stable and volatile pairs
                        </li>
                        <li className="flex gap-3 items-center">
                        <span className="text-primary">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-check-circle"
                            >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                        </span>
                        Self-optimizing liquidity flywheel
                        </li>
                        <li className="flex gap-3 items-center">
                        <span className="text-primary">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-check-circle"
                            >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                        </span>
                        100% of fees and incentives go to voters
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div className="lg:w-1/2 h-max lg:h-screen bg-connect dark:bg-connect-dark flex flex-col lg:justify-center">
                {!isConnected ? <div className="px-6">
                    <Link to="/" className="inline lg:hidden">
                    <div className="flex justify-center py-16">
                        <div className="flex items-center">
                        <img
                            src="velodrome.svg"
                            className="ml-2 mr-3 h-9"
                            alt="Velodrome Finance"
                        />
                        <img
                            src="wordmark.svg"
                            className="h-5 dark:hidden"
                            alt="Velodrome Finance"
                        />
                        <img
                            src="wordmark_white.svg"
                            className="hidden h-5 dark:inline"
                            alt="Velodrome Finance"
                        />
                        </div>
                    </div>
                    </Link>
                    <div className="flex justify-center">
                    <div className="bg-gray-500 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-lg text-sm py-6 sm:py-10 px-5 sm:px-12 max-w-sm">
                        <p>
                        You'll need an Ethereum
                        <br /> Wallet to sign-in.{" "}
                        <a
                            href="https://ethereum.org/en/wallets/"
                            target="_blank"
                            rel="noreferrer"
                            className="inline text-primary underline hover:no-underline"
                        >
                            Learn more
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={12}
                            height={12}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="inline ml-1"
                            >
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                            <polyline points="15 3 21 3 21 9" />
                            <line x1={10} x2={21} y1={14} y2={3} />
                            </svg>
                        </a>
                        </p>
                        <p className="pb-2 pt-4 sm:pr-12">
                        Once connected, you will have to sign a message to confirm that
                        you agree to the{" "}
                        <a className="text-primary underline hover:no-underline inline cursor-pointer">
                            legal disclaimer
                        </a>{" "}
                        before using this website.
                        </p>
                        <div className="pt-6 pb-3 text-xs opacity-50">Connect with:</div> 
                          <div className="space-y-2">
                            <button onClick={() => open()}
                                type="button"
                                className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1">
                                <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                                <div className="flex items-center gap-3 !justify-start">
                                    <img alt="icn-connect-injected" src="svg/icn-connect-injected.svg" className="h-6" />
                                    <span className="text-sm">Browser Wallet</span>
                                </div>
                                </span>
                            </button>
                            <button onClick={() => open()}
                                type="button"
                                className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1">
                                <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                                <div className="flex items-center gap-3 !justify-start">
                                    <img alt="icn-connect-walletConnect"
                                    src="svg/icn-connect-walletConnect.svg"
                                    className="h-6"
                                    />
                                    <span className="text-sm">WalletConnect</span>
                                </div>
                                </span>
                            </button>
                            <button onClick={() => open()}
                                type="button"
                                className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1">
                                <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                                <div className="flex items-center gap-3 !justify-start">
                                    <img alt="icn-connect-coinbaseWallet"
                                    src="svg/icn-connect-coinbaseWallet.svg"
                                    className="h-6"
                                    />
                                    <span className="text-sm">Coinbase Wallet</span>
                                </div>
                                </span>
                            </button>
                            <button 
                                disabled=""
                                type="button"
                                className="cursor-not-allowed opacity-50 text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1">
                                <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                                <div className="flex items-center gap-3 !justify-start">
                                    <img alt="icn-connect-safe" src="svg/icn-connect-safe.svg" className="h-6" />
                                    <span className="text-sm">Safe</span>
                                </div>
                                </span>
                            </button>
                            </div>
                    </div>
                    </div>
                </div> : null}

                {isConnected ?
                    <div className="flex justify-center">
                    <div className="w-full bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-lg text-sm py-6 sm:py-10 px-5 sm:px-12 max-w-sm">
                      <div className="pb-8">
                        <div className="text-xs pb-1">Connected with:</div>
                        <span className="font-mono " onClick={() => open()}> { address? truncate(address) : null} </span>
                      </div>
                      {/*<p className="pb-8 sm:pr-12">
                        You need to sign in to verify..
                      </p>
                      <p className="pb-8 sm:pr-12">
                        Please make sure that you have read and that you agree to the{" "}
                        <a className="text-primary underline hover:no-underline inline cursor-pointer">
                          legal disclaimer
                        </a>{" "}
                        before continuing.
                      </p>*/}
                      <button 
                        type="button"  disabled={isLoading?true:false} onClick={() => action()}
                        className={`${ isLoading? 'cursor-not-allowed' : ''} text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full`}
                      >
                        <span className="flex items-center rounded-md text-base px-5 py-2.5">
                        { isLoading? <span role="status">
                          <svg
                            fill="none"
                            viewBox="0 0 100 101"
                            className="inline animate-spin text-gray-200 fill-gray-600 dark:text-gray-600 dark:fill-gray-300 w-3 h-3 mr-3 mb-0.5">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"/>
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"/>
                          </svg>
                        </span> : null}
                          Sign-in
                        </span>
                      </button>
                    </div>
                  </div>
              : null}
                <div className="w-full lg:w-1/2 text-center lg:absolute lg:bottom-0 text-xs sm:text-sm py-8 pt-32 opacity-80 dark:opacity-80">
                    2023 © Velodrome Finance. v2.0.0beta+182acc
                </div>
                </div>
            </div>
        </div>

    )
}

export default Connect




