

const Landing = ({ Header, Footer }) => {

  return (
  <div>
    <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
      <div className="flex flex-col" />
    </div>
    <div className="bg-neutral-50 dark:bg-gray-900 ">
      <div className="bg-landing dark:bg-landing-dark">
        <div className="md:container md:mx-auto px-4 lg:px-8">
        {Header}
        </div>
        
        <div className="md:container md:mx-auto bg-worm dark:bg-worm-light lg:bg-auto bg-contain bg-no-repeat bg-[center_top_250px]">
        <div className="text-center space-y-8 mx-auto py-16 sm:py-40">
          <div className="text-xs sm:text-sm uppercase">Welcome to Velodrome</div>
          <div className="text-3xl sm:text-4xl md:text-5xl font-light dark:text-white">
            <div>The central trading &amp;</div>
            <div className="py-1 md:py-2">liquidity marketplace on</div>
            <div className="font-bold text-4xl sm:text-5xl md:text-6xl pt-1">
              Superchain
            </div>
          </div>
          <div className="flex items-center justify-center py-12">
            <img src="/svg/chains/10.svg" className="w-14 md:w-24" />
            <img src="/svg/chains/34443.svg" className="w-14 md:w-24 -ml-4" />
            <img src="/svg/chains/60808.svg" className="w-14 md:w-24 -ml-4" />
            <img src="/svg/chains/1135.svg" className="w-14 md:w-24 -ml-4" />
            <img src="/svg/chains/1750.svg" className="w-14 md:w-24 -ml-4" />
            <div className="bg-gray-900/5 dark:bg-white/5 rounded-full w-14 md:w-24 h-14 md:h-24 flex items-center justify-center -ml-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-plus"
              >
                <path d="M5 12h14" />
                <path d="M12 5v14" />
              </svg>
            </div>
          </div>
          <div className="text-sm sm:text-lg sm:pt-8 m-auto w-9/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12">
            Velodrome Finance is a next-generation AMM that combines the best of Curve,
            Convex and Uniswap, designed to serve as the liquidity hub for the
            Superchain. Velodrome NFTs vote on token emissions and receive incentives
            and fees generated by the protocol.
          </div>
        </div>

          <div className="px-8">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 xl:w-4/5 m-auto text-sm sm:text-base">
              <div className="flex gap-4 justify-between border border-gray-800 border-opacity-10 dark:border-white dark:border-opacity-10 rounded p-6">
                <div>
                  Stable &amp; Volatile
                  <br /> Trading for Low Fees
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
              <div className="flex gap-4 justify-between border border-gray-800 border-opacity-10 dark:border-white dark:border-opacity-10 rounded p-6">
                <div>
                  100% of Protocol Incentives
                  <br /> and Fees Go to Voters
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
              <div className="flex gap-4 justify-between border border-gray-800 border-opacity-10 dark:border-white dark:border-opacity-10 rounded p-6">
                <div>
                  Liquid Locked Positions
                  <br /> in the Form of NFTs
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
              <div className="flex gap-4 justify-between border border-gray-800 border-opacity-10 dark:border-white dark:border-opacity-10 rounded p-6">
                <div>
                  Permissionless Pools,
                  <br /> Gauges, and Incentives
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
              <div className="flex gap-4 justify-between border border-gray-800 border-opacity-10 dark:border-white dark:border-opacity-10 rounded p-6">
                <div>
                  Self-Optimizing
                  <br /> Liquidity Flywheel
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
              <div className="flex gap-4 justify-between border border-gray-800 border-opacity-10 dark:border-white dark:border-opacity-10 rounded p-6">
                <div>
                  Anti-dilution
                  <br /> rebases for voters
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
            </div>
            <div className="xl:w-4/5 m-auto">
              <div className="lg:flex justify-between md:pb-16 pt-16 md:pt-32">
                <div className="space-y-4">
                  <div className="text-left text-xs uppercase">How it works</div>
                  <div className="md:w-72 xl:w-96 font-serif text-left text-2xl xl:text-3xl xl:leading-[2.5rem]">
                    Designed to reward participants that enable the sustainable
                    growth of the protocol.
                  </div>
                </div>
                <div className="sm:pt-16 lg:pt-32 block flex w-full h-96 justify-center md:mb-8 lg:mb-16">
                  <svg
                    data-src="/svg/info-graph.svg"
                    className="injected-svg text-gray-900 dark:hidden"
                    viewBox="0 0 560 500"
                    preserveAspectRatio="xMidYMid meet"
                    src=""
                    fill="none"
                    height={402}
                    width={554}
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M0 0h554v402H0z"
                    />
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".5"
                      filter="url(#a-0)"
                    >
                      <path
                        d="M265.898 66.257a165.966 165.966 0 0 1-15.834 320.57l-.358-1.694a164.232 164.232 0 0 0 130.043-152.577A164.234 164.234 0 0 0 265.374 67.908l.524-1.65Z"
                        fill="#000"
                      />
                    </g>
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".5"
                      filter="url(#b-0)"
                    >
                      <path
                        d="M50.943 244.336A165.966 165.966 0 0 1 181.39 62.076l.358 1.695A164.235 164.235 0 0 0 52.663 244.129l-1.72.207Z"
                        fill="#000"
                      />
                    </g>
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".5"
                      filter="url(#c-0)"
                    >
                      <path
                        d="M262.552 52.138a171.505 171.505 0 0 1 187.093 128.826l-1.578.393A169.879 169.879 0 0 0 262.748 53.753l-.196-1.615Z"
                        fill="#000"
                      />
                    </g>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".1"
                      d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
                      fill="currentColor"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      id="d"
                      d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
                      fill="none"
                      strokeWidth={1}
                      stroke="#EE2524"
                      strokeDasharray={3274}
                      strokeDashoffset={3274}
                    >
                      <animate
                        attributeName="stroke-dashoffset"
                        dur="7s"
                        values="3274;1900"
                        restart="always"
                        repeatCount="indefinite"
                      />
                    </path>
                    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="111.316" y="301.144">
                          LOW SLIPPAGE
                        </tspan>
                      </text>
                      <circle cx="87.42" cy="296.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="33.765" y="143.144">
                          FEES
                        </tspan>
                      </text>
                      <circle cx="79.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="259.891" y="143.144">
                          VELO EMISSIONS
                        </tspan>
                      </text>
                      <circle cx="381.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="481.42" y="268.144">
                          LIQUIDITY
                        </tspan>
                      </text>
                      <circle cx="460.42" cy="263.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="446.42" y="105.144">
                          INCENTIVES
                        </tspan>
                      </text>
                      <circle cx="430.42" cy="100.781" r={7} />
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <path
                        id="slippage"
                        d="M62.0291 213.5C64.6734 303.971 138.845 376.5 229.956 376.5C240.051 376.5 249.938 375.61 259.544 373.903L259.369 372.918C249.821 374.615 239.992 375.5 229.956 375.5C139.397 375.5 65.6735 303.419 63.0295 213.5H62.0291Z"
                        fill="none"
                        strokeWidth={1}
                        stroke="#EE2524"
                        strokeDasharray={0}
                        strokeDashoffset={577}
                      >
                        <animate
                          attributeName="stroke-dasharray"
                          dur="7s"
                          values="0;15;4;2;0"
                          restart="always"
                          repeatCount="indefinite"
                        />
                      </path>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="111.316" y="301.144">
                          LOW SLIPPAGE
                        </tspan>
                      </text>
                      <circle cx="87.42" cy="296.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="33.765" y="143.144">
                          FEES
                        </tspan>
                      </text>
                      <circle cx="79.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="259.891" y="143.144">
                          VELO EMISSIONS
                        </tspan>
                      </text>
                      <circle cx="381.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="481.42" y="268.144">
                          LIQUIDITY
                        </tspan>
                      </text>
                      <circle cx="460.42" cy="263.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="446.42" y="105.144">
                          INCENTIVES
                        </tspan>
                      </text>
                      <circle cx="430.42" cy="100.781" r={7} />
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <circle r={8} fill="#fff" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M-3 12C3.627 12 9 6.627 9 0S3.627-12-3-12-15-6.627-15 0s5.373 12 12 12Zm-2.707-7.707a1 1 0 1 0 1.414 1.414L.525.89a1 1 0 0 0 0-1.414l-4.818-4.818a1 1 0 1 0-1.414 1.414l4.11 4.111-4.11 4.11Z"
                        fill="#EE2524"
                      />
                      <animateMotion
                        dur="7s"
                        repeatCount="indefinite"
                        rotate="auto"
                        calcMode="spline"
                        keyPoints="0;0.051;0.22;0.323;0.412"
                        keyTimes="0; 0.1; 0.5; 0.75; 1"
                        keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
                      >
                        <mpath
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="#d"
                        />
                      </animateMotion>
                    </g>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={143}
                      y={28}
                      width={145}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={167} y="52.364">
                        veVELO VOTERS
                      </tspan>
                    </text>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={1}
                      y={206}
                      width={105}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={25} y="230.364">
                        TRADERS
                      </tspan>
                    </text>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={431}
                      y={149}
                      width={123}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={455} y="173.364">
                        PROTOCOLS
                      </tspan>
                    </text>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={222}
                      y={357}
                      width={72}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={246} y="381.364">
                        LP’s
                      </tspan>
                    </text>
                    <defs xmlns="http://www.w3.org/2000/svg">
                      <filter
                        id="a-0"
                        x="234.706"
                        y="51.257"
                        width="161.975"
                        height="350.57"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation="7.5"
                          result="effect1_foregroundBlur_1_2282"
                        />
                      </filter>
                      <filter
                        id="b-0"
                        x="34.748"
                        y="47.077"
                        width="161.999"
                        height="212.26"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation="7.5"
                          result="effect1_foregroundBlur_1_2282"
                        />
                      </filter>
                      <filter
                        id="c-0"
                        x="247.552"
                        y="35.888"
                        width="217.093"
                        height="160.469"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation="7.5"
                          result="effect1_foregroundBlur_1_2282"
                        />
                      </filter>
                    </defs>
                  </svg>
                  <svg
                    data-src="/svg/info-graph-dark.svg"
                    className="injected-svg text-white hidden dark:inline"
                    viewBox="0 0 560 500"
                    preserveAspectRatio="xMidYMid meet"
                    src=""
                    fill="none"
                    height={402}
                    width={554}
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M0 0h554v402H0z"
                    />
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".5"
                      filter="url(#a-1)"
                    >
                      <path
                        d="M265.898 66.257a165.966 165.966 0 0 1-15.834 320.57l-.358-1.694a164.232 164.232 0 0 0 130.043-152.577A164.234 164.234 0 0 0 265.374 67.908l.524-1.65Z"
                        fill="#000"
                      />
                    </g>
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".5"
                      filter="url(#b-1)"
                    >
                      <path
                        d="M50.943 244.336A165.966 165.966 0 0 1 181.39 62.076l.358 1.695A164.235 164.235 0 0 0 52.663 244.129l-1.72.207Z"
                        fill="#000"
                      />
                    </g>
                    <g
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".5"
                      filter="url(#c-1)"
                    >
                      <path
                        d="M262.552 52.138a171.505 171.505 0 0 1 187.093 128.826l-1.578.393A169.879 169.879 0 0 0 262.748 53.753l-.196-1.615Z"
                        fill="#000"
                      />
                    </g>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      opacity=".1"
                      d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
                      fill="currentColor"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      id="d"
                      d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
                      fill="none"
                      strokeWidth={1}
                      stroke="#EE2524"
                      strokeDasharray={3274}
                      strokeDashoffset={3274}
                    >
                      <animate
                        attributeName="stroke-dashoffset"
                        dur="7s"
                        values="3274;1900"
                        restart="always"
                        repeatCount="indefinite"
                      />
                    </path>
                    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="111.316" y="301.144">
                          LOW SLIPPAGE
                        </tspan>
                      </text>
                      <circle cx="87.42" cy="296.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="33.765" y="143.144">
                          FEES
                        </tspan>
                      </text>
                      <circle cx="79.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="259.891" y="143.144">
                          VELO EMISSIONS
                        </tspan>
                      </text>
                      <circle cx="381.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="481.42" y="268.144">
                          LIQUIDITY
                        </tspan>
                      </text>
                      <circle cx="460.42" cy="263.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="446.42" y="105.144">
                          INCENTIVES
                        </tspan>
                      </text>
                      <circle cx="430.42" cy="100.781" r={7} />
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <path
                        id="slippage"
                        d="M62.0291 213.5C64.6734 303.971 138.845 376.5 229.956 376.5C240.051 376.5 249.938 375.61 259.544 373.903L259.369 372.918C249.821 374.615 239.992 375.5 229.956 375.5C139.397 375.5 65.6735 303.419 63.0295 213.5H62.0291Z"
                        fill="none"
                        strokeWidth={1}
                        stroke="#EE2524"
                        strokeDasharray={0}
                        strokeDashoffset={577}
                      >
                        <animate
                          attributeName="stroke-dasharray"
                          dur="7s"
                          values="0;15;4;2;0"
                          restart="always"
                          repeatCount="indefinite"
                        />
                      </path>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="111.316" y="301.144">
                          LOW SLIPPAGE
                        </tspan>
                      </text>
                      <circle cx="87.42" cy="296.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="33.765" y="143.144">
                          FEES
                        </tspan>
                      </text>
                      <circle cx="79.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="259.891" y="143.144">
                          VELO EMISSIONS
                        </tspan>
                      </text>
                      <circle cx="381.42" cy="137.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="481.42" y="268.144">
                          LIQUIDITY
                        </tspan>
                      </text>
                      <circle cx="460.42" cy="263.781" r={7} />
                      <text
                        xmlSpace="preserve"
                        style={{ whiteSpace: "pre" }}
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={500}
                        letterSpacing=".04em"
                      >
                        <tspan x="446.42" y="105.144">
                          INCENTIVES
                        </tspan>
                      </text>
                      <circle cx="430.42" cy="100.781" r={7} />
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <circle r={8} fill="#fff" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M-3 12C3.627 12 9 6.627 9 0S3.627-12-3-12-15-6.627-15 0s5.373 12 12 12Zm-2.707-7.707a1 1 0 1 0 1.414 1.414L.525.89a1 1 0 0 0 0-1.414l-4.818-4.818a1 1 0 1 0-1.414 1.414l4.11 4.111-4.11 4.11Z"
                        fill="#EE2524"
                      />
                      <animateMotion
                        dur="7s"
                        repeatCount="indefinite"
                        rotate="auto"
                        calcMode="spline"
                        keyPoints="0;0.051;0.22;0.323;0.412"
                        keyTimes="0; 0.1; 0.5; 0.75; 1"
                        keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
                      >
                        <mpath
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="#d"
                        />
                      </animateMotion>
                    </g>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={143}
                      y={28}
                      width={145}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={167} y="52.364">
                        veVELO VOTERS
                      </tspan>
                    </text>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={1}
                      y={206}
                      width={105}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={25} y="230.364">
                        TRADERS
                      </tspan>
                    </text>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={431}
                      y={149}
                      width={123}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={455} y="173.364">
                        PROTOCOLS
                      </tspan>
                    </text>
                    <rect
                      xmlns="http://www.w3.org/2000/svg"
                      x={222}
                      y={357}
                      width={72}
                      height={40}
                      rx={20}
                      fill="currentColor"
                    />
                    <text
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000"
                      xmlSpace="preserve"
                      style={{ whiteSpace: "pre" }}
                      fontFamily="Inter"
                      fontSize={12}
                      fontWeight="bold"
                      letterSpacing="0em"
                    >
                      <tspan x={246} y="381.364">
                        LP’s
                      </tspan>
                    </text>
                    <defs xmlns="http://www.w3.org/2000/svg">
                      <filter
                        id="a-1"
                        x="234.706"
                        y="51.257"
                        width="161.975"
                        height="350.57"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation="7.5"
                          result="effect1_foregroundBlur_1_2282"
                        />
                      </filter>
                      <filter
                        id="b-1"
                        x="34.748"
                        y="47.077"
                        width="161.999"
                        height="212.26"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation="7.5"
                          result="effect1_foregroundBlur_1_2282"
                        />
                      </filter>
                      <filter
                        id="c-1"
                        x="247.552"
                        y="35.888"
                        width="217.093"
                        height="160.469"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation="7.5"
                          result="effect1_foregroundBlur_1_2282"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 text-sm py-0 md:py-8 lg:py-12">
                <div className="grid place-content-between space-y-2 shadow bg-white bg-opacity-30 hover:bg-opacity-50 dark:bg-gray-900 dark:hover:bg-opacity-10 rounded-lg dark:bg-opacity-5 cursor-pointer">
                  <div className="px-8 pt-6">
                    <div className="font-bold pb-3">Traders</div>
                    <div className="opacity-70">
                      Swap tokens with minimal slippage and pay some of the lowest
                      fees to VELO lockers.
                    </div>
                  </div>
                  <img
                    src="/images/landing-traders-light.svg"
                    alt="Landing Traders Light"
                    className={`w-full rounded-b-lg inline dark:hidden `}
                  />
                  <img
                    src="/images/landing-traders-dark.svg"
                    alt="Landing Traders Dark"
                    className={`w-full rounded-b-lg hidden dark:inline `}
                  />
                </div>
                <div className="grid place-content-between space-y-2 shadow bg-white bg-opacity-30 hover:bg-opacity-50 dark:bg-gray-900 dark:hover:bg-opacity-10 rounded-lg dark:bg-opacity-5 cursor-pointer">
                  <div className="px-8 pt-6">
                    <div className="font-bold pb-3">Liquidity Providers</div>
                    <div className="opacity-70">
                      Deposit the tokens used for trading on Velodrome and receive
                      VELO emissions as rewards.
                    </div>
                  </div>
                  <img
                    src="/images/landing-lp-light.svg"
                    alt="Landing Traders Light"
                    className={`w-full rounded-b-lg inline dark:hidden `}
                  />
                  <img
                    src="/images/landing-lp-dark.svg"
                    alt="Landing Traders Dark"
                    className={`w-full rounded-b-lg hidden dark:inline`}
                  />
                </div>
                <div className="grid place-content-between space-y-2 shadow bg-white bg-opacity-30 hover:bg-opacity-50 dark:bg-gray-900 dark:hover:bg-opacity-10 rounded-lg dark:bg-opacity-5 cursor-pointer">
                  <div className="px-8 pt-6">
                    <div className="font-bold pb-3">Protocols</div>
                    <div className="opacity-70">
                      Offer incentives to veVELO voters to attract votes / VELO
                      emissions to their pools, allowing them to build liquidity
                      at a low cost.
                    </div>
                  </div>
                  <img
                    src="/images/landing-protocol-light.svg"
                    alt="Landing Protocol Light" className={`w-full rounded-b-lg inline dark:hidden `} />
                  <img
                    src="/images/landing-protocol-dark.svg" alt="" 
                    className={`w-full rounded-b-lg hidden dark:inline`}
                  />
                </div>
                <div className="grid place-content-between space-y-2 shadow bg-white bg-opacity-30 hover:bg-opacity-50 dark:bg-gray-900 dark:hover:bg-opacity-10 rounded-lg dark:bg-opacity-5 cursor-pointer">
                  <div className="px-8 pt-6">
                    <div className="font-bold pb-3">veVELO Voters</div>
                    <div className="opacity-70">
                      Vote on which pools will earn VELO emissions and receive
                      100% of incentives and fees for the pools they vote for. Any
                      VELO holder can lock their tokens to convert to veVELO.
                    </div>
                  </div>
                  <img
                    src="/images/landing-velo-light.svg"
                    alt="Landing Velo Light"
                    className={`w-full rounded-b-lg inline dark:hidden`}
                  />
                  <img
                    src="/images/landing-velo-dark.svg"
                    alt="Landing Velo Dark"
                    className={`w-full rounded-b-lg hidden dark:inline`}
                  />
                </div>
              </div>
              <div className="space-y-16 md:space-y-0 md:flex justify-around pt-16 pb-16 sm:pb-28 opacity-90">
                <div className="text-center space-y-2">
                  <div className="text-3xl lg:text-4xl font-serif">$ 125.46M</div>
                  <div className="text-xs uppercase">Total Value Locked</div>
                </div>
                <div className="text-center space-y-2">
                  <div className="text-3xl lg:text-4xl font-serif">$ 4.28B</div>
                  <div className="text-xs uppercase">Annualized Trading</div>
                </div>
                <div className="text-center space-y-2 hidden md:inline">
                  <div className="text-3xl lg:text-4xl font-serif">$ 4.03M</div>
                  <div className="text-xs uppercase">Annualized Fees</div>
                </div>
                <div className="text-center space-y-2">
                  <div className="text-3xl lg:text-4xl font-serif">27.11K</div>
                  <div className="text-xs uppercase">Active Users</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:px-40 md:container md:mx-auto">
        <div className="px-4 flex justify-around py-16 border-b border-gray-900 border-opacity-5 dark:border-white dark:border-opacity-5">
          <div className="w-12 opacity-70 hover:opacity-100">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://www.optimism.io/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/optimism.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":r0:"
              role="tooltip"
              style={{ position: "absolute", top: 2799, left: 8 }}
            >
              <div className="relative z-20">Optimism Foundation</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ bottom: "-4px", left: "25.9725px" }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a
                href="https://www.lyra.finance/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="" src="partners/lyra.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":r2:"
              role="tooltip"
              style={{ position: "absolute", top: 2799, left: "77.789px" }}
            >
              <div className="relative z-20">Lyra Finance</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ bottom: "-4px", left: 48 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://synthetix.io/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/synthetix.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":r4:"
              role="tooltip"
              style={{ position: "absolute", top: 2799, left: "181.207px" }}
            >
              <div className="relative z-20">Synthetix</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ bottom: "-4px", left: 37 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://frax.finance/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/frax.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":r6:"
              role="tooltip"
              style={{ position: "absolute", top: 2779, left: 271 }}
            >
              <div className="relative z-20">Frax Finance</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ bottom: "-4px", left: 39 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden sm:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://lido.fi/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/lido.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":r8:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Lido Finance</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden sm:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a
                href="https://www.inverse.finance/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="" src="partners/inverse.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":ra:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Inverse Finance</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden md:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a
                href="https://abracadabra.money/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="" src="partners/abracadabra.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":rc:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Abracadabra Money</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden md:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://www.liquity.org/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/liquity.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":re:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Liquity</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden md:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://thalesmarket.io/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/thales.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":rg:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Thales</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden lg:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://www.mai.finance/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/mai.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":ri:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Mai Finance</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden lg:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://rocketpool.net/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/rocketpool.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":rk:"
              role="tooltip"
              style={{
                position: "absolute",
                top: "2611.97px",
                left: "798.516px"
              }}
            >
              <div className="relative z-20">Rocket Pool</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ left: 47, bottom: "-4px" }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden lg:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://makerdao.com/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/makerdao.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":rm:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">MakerDAO</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="w-12 opacity-70 hover:opacity-100 hidden lg:inline">
            <div
              className="inline cursor-pointer"
              data-testid="flowbite-tooltip-target"
            >
              <a href="https://angle.money/" target="_blank" rel="noreferrer">
                <img alt="" src="partners/angle.svg" />
              </a>
            </div>
            <div
              data-testid="flowbite-tooltip"
              tabIndex={-1}
              className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
              id=":ro:"
              role="tooltip"
              style={{ position: "absolute", top: 8, left: 8 }}
            >
              <div className="relative z-20">Angle</div>
              <div
                className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                data-testid="flowbite-tooltip-arrow"
                style={{ top: "-4px", left: 0 }}
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-xs text-gray-600 dark:text-gray-400 py-4 px-12 sm:px-14 border-b border-gray-900 border-opacity-5 dark:border-white dark:border-opacity-5">
          <div>
            Data and metrics are openly available on{" "}
            <a
              className="underline hover:no-underline hover:text-gray-700 dark:hover:text-gray-400"
              href="https://tokenterminal.com/terminal/projects/velodrome"
              target="_blank"
              rel="noreferrer"
            >
              Token Terminal
            </a>{" "}
            and{" "}
            <a
              className="underline hover:no-underline hover:text-gray-700 dark:hover:text-gray-300"
              href="https://dune.com/0xkhmer/velodrome"
              target="_blank"
              rel="noreferrer"
            >
              Dune
            </a>
          </div>
        </div>
        <div className="mt-32 sm:mt-64 bg-cyclist dark:bg-cyclist-light pt-32 sm:pb-32 bg-no-repeat bg-center bg-top">
          <div className="w-2/3 lg:w-2/3 text-center mx-auto py-16 space-y-6">
            <div className="text-xl md:leading-[3rem] md:text-3xl font-bold font-bold font-serif sm:mt-16">
              Looking to get started
              <br /> with Velodrome Finance?
            </div>
            <a
              href="/join"
              className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-48 mx-auto"
            >
              <span className="flex items-center rounded-md text-sm px-4 py-2">
                Onboarding Guide
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="md:container md:mx-auto px-4 lg:px-8 py-0 lg:pb-4">
      {Footer}
      </div>
    </div>
  </div>
  );
}

export default Landing;