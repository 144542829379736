import './App.css';

import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Brand from './components/Brand';
import Connect from './components/Connect';
import Docs from './components/Docs';
import Footer from './components/Footer';
import Header from './components/Header';
import Landing from './components/Landing';
import Liquidity from './components/Liquidity';
import Security from './components/Security';
import Swap from './components/Swap';
import TokenList from './components/TokenList';

import { GatekeeperProvider } from "./context/Gatekeeper";
import {
  mainnet,
  optimism, base, arbitrum, polygon, avalanche
} from './utils/chains';
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const projectId = process.env.REACT_APP_PROJECT_ID

  // 3. Create modal
const metadata = {
  name: 'Velodrome Finance',
  description: 'Velodrome Finance: The central liquidity hub on Optimism network.',
  url: 'https://www.velodrome.finance/',
  icons: ['https://www.velordromefinance.app/velodrome.svg']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 10 }),
  chains: [ optimism, mainnet, base, arbitrum, polygon, avalanche ],
  projectId,
  excludeWalletIds: [
    'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393'
  ]
})


function App() {

  return (
    <GatekeeperProvider>
      <RoutesContext />
    </GatekeeperProvider>
  );
}


function RoutesContext() {
  return(
    <Routes>
    <Route path="/" element={<Landing Header={<Header/>} Footer={<Footer/>} />} />
    <Route path="/landing" element={<Navigate to="/" replace />} />
    <Route path="/swap" element={<Swap Header={<Header/>} Footer={<Footer/>} />} />
    <Route path="/liquidity" element={<Liquidity Header={<Header/>} Footer={<Footer/>} />} />
    <Route path="/connect" element={<Connect />} />
    <Route path="/deposit" element={<Connect />} />
    <Route path="/join" element={<Navigate to="/connect" replace />} />
    <Route path="/brand" element={<Brand Header={<Header/>} Footer={<Footer/>} />} />
    <Route path="/security" element={<Security Header={<Header/>} Footer={<Footer/>} />} />
    <Route path="/docs" element={<Docs Header={<Header/>} Footer={<Footer/>} />} />
    <Route path="/tokenlist" element={<TokenList Header={<Header/>} Footer={<Footer/>} />} />
  </Routes>
  )
}

export default App;



